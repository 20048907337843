<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import controlsTable from "./components/controlsTable";
export default {
  page: {
    title: "Contrôls",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, controlsTable },
  data() {
    return {
      title: "Contrôls",
      items: [
        {
          text: "HSE",
          href: "javascript:void(0)",
        },
        {
          text: "Contrôls",
          active: true,
        },
      ],
    }
  },
  
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <controls-table/>
  </Layout>
</template>