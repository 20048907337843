<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      tableData: [],
      title: "Repas emportés",
      requests: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
    };
  },
  computed: {
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    this.totalRows = this.tableData.length;
    this.refreshControlsTable();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    refreshTable() {
      // console.log(this);
    },
    deleteRequest(reqRef, reqUid) {
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer la requête: " + reqRef + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/ops/requests/visitors_support/delete/" + reqUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  _this.refreshControlsTable();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    refreshControlsTable() {
      this.$http
        .post("/ops/controls/list", {
          controlType: this.$route.params.category
        })
        .then((res) => (this.requests = res.data.original.list))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <a
            @click="refreshControlsTable()"
            href="javascript:void()"
            ><h1><i class="bx bx-rotate-right" style="float: right"></i></h1
          ></a>
          <router-link v-if="$can('create_vsr')" :to="{ name: 'ops.hse.index.category.new', params: { category: this.$route.params.category }, }"
            ><b-button variant="primary"
              >Nouveau contrôl</b-button
            ></router-link
          >
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Recherche..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive mb-0">
              <div class="table-responsive mb-0">
                <table class="table table-centered table-nowrap">
                  <thead class="thead-light">
                    <tr>
                      <th>Référence</th>
                      <th>Demandé par</th>
                      <th>Date de requête</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="request in requests" :key="request.id">
                      <td>
                        <router-link
                          :to="{
                            name: 'requests.visitors_support.display',
                            params: { uid: request.uuid },
                          }"
                          >{{ request.ref }}</router-link>
                      </td>
                      <td>{{ request.requestedBy }}</td>
                      <td>{{ request.created_at }}</td>
                      <td><span v-html="request.status"></span></td>
                      <td>
                        <router-link v-if="request.statusPlain == 'draft' && $can('edit_vsr')"
                          :to="{
                            name: 'requests.visitors_support.edit',
                            params: { uid: request.uuid },
                          }"
                          class="mr-3 text-primary"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Modifier"
                          data-original-title="Edit"
                          ><i class="mdi mdi-pencil font-size-18"></i
                        ></router-link>

                        <a
                          v-if="request.statusPlain == 'draft' && $can('delete_vsr')"
                          @click="deleteRequest(request.ref, request.uuid)"
                          class="text-danger"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Supprimer"
                          data-original-title="Delete"
                          ><i class="mdi mdi-close font-size-18"></i
                        ></a>
                        <span v-if="request.statusPlain != 'draft'">Aucune action n'est disponible</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>